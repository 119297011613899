.pdf-export,
.pdf-export p,
.pdf-export div {
  font-size: 1rem;
}

.pdf-export.small-text,
.pdf-export.small-text p,
.pdf-export.small-text div {
  font-size: 0.8rem; /* Smaller text size */
}

.hide-for-pdf {
  display: none !important;
}

/* Hide ExpandButton in cloned element for PDF */
.clone-for-pdf #expand-button {
  display: none;
}
.clone-for-pdf #close-button {
  display: none;
}

.pdf-export table {
  transform: scale(0.9); /* Adjust scale as needed */
  transform-origin: top left;
  page-break-inside: avoid; /* Avoid page breaks inside tables */
}

.clone-for-pdf {
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: flex-start; /* Vertically center the content */
  height: 100%; /* Ensure the container takes full height */
  page-break-after: auto; /* Allow page breaks after .clone-for-pdf elements */
}

.clone-for-pdf .pdf-header-text {
  font-size: 1.1rem;
  font-family: "MontserratBold";
  /* Add any other styles specific to the PDF header */
}

.pdf-export.small-text .pdf-header-text {
  font-size: 1rem; /* Adjusted size for smaller header text */
}

/* Additional Page Break Controls */
.pdf-export tr {
  page-break-inside: avoid; /* Avoid page breaks inside table rows */
}

.pdf-export h1,
.pdf-export h2,
.pdf-export h3 {
  page-break-after: avoid; /* Avoid page breaks immediately after headings */
  page-break-before: avoid; /* Avoid page breaks immediately before headings */
}
